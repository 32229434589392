import React from 'react';

import logo from '../assets/images/carrot-logo-deep-grey.svg';
// import logo from '../assets/images/carrot-logo-orange-xmas.png'; // xmas

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section id="footer__section">
      <div className="web-display-none mobile-display-block mobile-logo">
        <a href="/">
          <img src={logo} alt="logo" width="110" />
        </a>
      </div>

      <div className="links">
        <div className="mobile-display-none">
          <a href="/">
            <img src={logo} alt="logo" width="110" />
          </a>
        </div>

        <div className="link-column">
          <div className="title">
            <div className="title-vert" />
            Platform
          </div>
          <div className="list">
            <ul>
              <li><a href="/signup">Sign Up</a></li>
              <li><a href="/signin">Sign In</a></li>
            </ul>
          </div>
        </div>

        <div className="link-column">
          <div className="title">
            <div className="title-vert" />
            Legal
          </div>
          <div className="list">
            <ul>
              <li><a href="/user-agreement">User Agreement</a></li>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
            </ul>
          </div>
        </div>

        <div className="link-column">
          <div className="title">
            <div className="title-vert" />
            Socials
          </div>
          <div className="list">
            <ul>
              <li><a href="https://www.instagram.com/getcarrotcredit/">Instagram</a></li>
              <li><a href="https://www.twitter.com/getcarrotcredit/">X</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div className="copy-policy">
        <div className="copy">
          ©
          {' '}
          {currentYear}
          , CarrotCredit Inc. All Rights Reserved.
        </div>
        <div className="policy">
          By using this website, you accept our
          {' '}
          <a href="/user-agreement">User Agreement</a>
          {' '}
          and
          {' '}
          <a href="/privacy-policy">Privacy Policy</a>
          .
        </div>
      </div>
    </section>
  );
};
export default Footer;
