import Signup from './Signup';
import Signin from './Signin';
import Forgot from './Forgot';
import Dashboard from './Dashboard';
import NotFound from './NotFound';
import Offer from './Offer';
import Onboarding from './Onboarding';
import Repayment from './Repayment';
import Withdrawal from './Withdrawal';
import { PrivacyPolicy, UserAgreement } from './Agreement';
import Faqs from './Faqs';
import Home2 from './Home2';
import Business from './Business';
import Individual from './Individual';
import Blog from './Blog';
import Profile from './Profile';
import Signon from './Signon';
import Waitlist from './Waitlist';

const baseRoutes = {
  private: [
    {
      component: Offer,
      exact: true,
      path: '/offer',
    },
    {
      component: Dashboard,
      exact: true,
      path: '/dashboard',
    },
    {
      component: Repayment,
      exact: true,
      path: '/repay',
    },
    {
      component: Withdrawal,
      exact: true,
      path: '/withdrawal',
    },
    {
      component: Profile,
      exact: true,
      path: '/profile',
    },
    {
      component: Waitlist,
      exact: true,
      path: '/waitlist',
    },
  ],
  public: [
    {
      component: Home2,
      exact: true,
      path: '/',
    },
    {
      component: Blog,
      exact: true,
      path: '/blog',
    },
    {
      component: Individual,
      exact: true,
      path: '/home/individual',
    },
    {
      component: Business,
      exact: true,
      path: '/home/business',
    },
    {
      component: Faqs,
      exact: true,
      path: '/faqs',
    },
    {
      component: Signup,
      exact: true,
      path: '/signup',
    },
    {
      component: Signon,
      exact: true,
      path: '/signon',
    },
    {
      component: Signin,
      exact: true,
      path: '/signin',
    },
    {
      component: Signin,
      exact: true,
      path: '/signin/:code',
    },
    {
      component: NotFound,
      exact: true,
      path: '/404',
    },
    {
      component: Onboarding,
      exact: true,
      path: '/onboarding/:code/:application_id',
    },
    {
      component: UserAgreement,
      exact: true,
      path: '/user-agreement',
    },
    {
      component: PrivacyPolicy,
      exact: true,
      path: '/privacy-policy',
    },
    {
      component: Forgot,
      exact: true,
      path: '/forgot',
    },
    {
      component: Forgot,
      exact: true,
      path: '/forgot',
    },
  ],
};

export default baseRoutes;
