import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import 'animate.css';

import '../../Faqs/faq.css';

import {
  FaqsContainer, AboutSection, ListHeading, ListIcon, ListCaption, Info, ListContent, AboutList,
  AboutDropDown, MobileAboutHeader, ToggleIcon, Drop, ListDivider
} from './FaqsElement';
import { topics } from '../../Faqs/Topics';

const Faqs = () => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(0);
  const [visibleQuestion, setVisibleQuestion] = useState(0);

  const handleDropDown = () => {
    setShow(!show);
  };

  const chooseActiveTopic = i => () => {
    setActive(i);
    handleDropDown();
    setVisibleQuestion(0);
  };

  const chooseActiveQuestion = i => () => {
    setVisibleQuestion(visibleQuestion === i ? null : i);
  };

  useEffect(() => {
    document.body.classList.remove('no-overflow');
    document.body.classList.add('overflow');
  }, []);

  return (
    <div>
      <FaqsContainer>
        <AboutSection>
          <AboutList>
            <div>
              {topics.map((topic, i) => (
                <p key={i} onClick={chooseActiveTopic(i)} style={{ color: '#4A4A4A', fontFamily: i === active ? 'Gilroy-Bold' : 'Gilroy-Regular' }}>{topic.heading}</p>
              ))}
            </div>
          </AboutList>
          <Drop>
            <AboutDropDown onClick={handleDropDown}>
              <MobileAboutHeader>{topics[active].heading}</MobileAboutHeader>
              <ToggleIcon>{show ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</ToggleIcon>
            </AboutDropDown>
            {show && (
            <div className="animate__animated animate__fadeIn animate__delay-100s">
              {topics.map((topic, i) => (
                <p key={i} onClick={chooseActiveTopic(i)} style={{ color: i === active ? '#51B57C' : '#353535', fontWeight: i === active ? 'bold' : 'normal' }}>{topic.heading}</p>
              ))}
            </div>
            )}
          </Drop>
          <div>
            {topics[active].qna.map((qna, i) => (
              <Info key={i}>
                <ListContent onClick={chooseActiveQuestion(i)}>
                  <ListHeading>{qna.question}</ListHeading>
                  <ListIcon>{visibleQuestion !== i ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}</ListIcon>
                </ListContent>
                <ListCaption className={visibleQuestion === i ? 'question-container-open' : 'question-container-close'}>{qna.answer}</ListCaption>
                <ListDivider />
              </Info>
            ))}
          </div>
        </AboutSection>
      </FaqsContainer>
    </div>
  );
};

export default Faqs;
