import styled from 'styled-components';
import { deviceQuery } from '../../Faqs/themes';

export const FaqsContainer = styled.div`
  font-family: 'Gilroy-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.02em;
`;

export const Heading = styled.h1`
  font-family: 'Gilroy-ExtraBold';
  font-size: 1.8rem;
  line-height: 2rem;
  margin: 0;
  color: #121e0e;
  padding: 0 5% 13% 5%;
  @media ${deviceQuery.tablet} {
    font-size: 1.55rem;
    width: 75%;
    line-height: 2rem;
    padding: 0 5% 8% 5%;
    margin: auto;
    margin-bottom: 8px;
  }
`;
export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11%;
  margin-top: -73px;
  @media ${deviceQuery.tablet} {
    display: block;
    img {
      display: none;
    }
    text-align: center;
    padding: 0;
  }
`;
export const Caption = styled.p`
  margin: 10px 0 0 0;
  @media ${deviceQuery.tablet} {
    line-height: 25.17px;
    width: 308px;
    margin: 0;
    margin: auto;
  }
`;

export const AboutSection = styled.div`
  display: flex;
  width: 100%;
  margin: 68px 0 60px 0;
  justify-content: space-between;
  @media ${deviceQuery.tablet} {
    display: block;
  }
`;
export const ListHeading = styled.p`
  font-family: 'Gilroy-Regular';
  font-style: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #121e0e;

  cursor: pointer;
  width: 85%;
  margin: 27px 0;
  @media ${deviceQuery.tablet} {
    font-size: 1.3rem !important;
    line-height: 1.7rem !important;
  }
`;
export const ListIcon = styled.span`
  margin-right: 38px;
  color: #51b57c;
  font-size: 22px;
  cursor: pointer;
`;
export const ListCaption = styled.p`
  font-family: 'Gilroy-Regular';
  font-size: 1.1rem;
  font-style: normal;
  line-height: 1.8rem;
  width: 85%;
  overflow: hidden;
  color: #121e0e @media ${deviceQuery.tablet} {
    font-size: 1.2rem;
    width: auto;
    margin-top: -5px;
  }
`;
export const ListContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Info = styled.div`
  border-bottom: 0px solid #121e0e;
  width: 100%;
  @media ${deviceQuery.tablet} {
    width: 85%;
  }
  &:last-child {
    border-bottom: none;
    margin-bottom: -24px;
  }
`;
export const ListDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #4A4A4A;
`;
export const AboutHeader = styled.p`
  font-size: 18px;
  font-weight: 600;
  @media ${deviceQuery.tablet} {
    display: none;
  }
`;
export const MobileAboutHeader = styled.p`
  margin: 0 0 10px 0;
  width: 240px;
  font-family: 'Gilroy-SemiBold';
`;

export const AboutList = styled.div`
  padding: 40px;
  padding-bottom: calc(40px - 1rem);
  background-color: #FDEBE7;
  border-radius: 24px;
  max-width: max-content;
  margin-right: 40px;
  margin-top: 27px;
  & p {
    cursor: pointer;
    white-space: pre;
    font-family: 'Gilroy-Regular';
    font-size: 1.1rem;
    font-style: normal;
    line-height: 1.8rem;
  }
  @media ${deviceQuery.tablet} {
    display: none;
  }
`;

export const AboutDropDown = styled.div`
  display: block;
  @media ${deviceQuery.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 47px;
    margin-bottom: 12px;
    border-bottom: 1px solid #121e0e;
    width: auto;
  }
`;
export const Drop = styled.div`
  display: none;
  @media ${deviceQuery.tablet} {
    display: block;
    margin: auto;
    margin-top: -41px;
    p {
      cursor: pointer;
      font-size: 1.3rem;
      line-height: 1.7rem;
    }
  }
`;
export const ToggleIcon = styled.div`
  cursor: pointer;
`;
